import { all, put, takeLatest, select, call } from 'redux-saga/effects';
import { navigate } from 'gatsby-link';
import { API, Auth } from 'aws-amplify';
import { config } from '../utils/amplify';
import * as contentful from "contentful";
import algoliasearch from 'algoliasearch';
import pageListSagas from '../templates/PageList/sagas';
import * as mangopay from '../../src/utils/libs/mangopay.js'
import * as configAlgolia from '../../src/utils/libs/config';
import sellerPageSagas from '../templates/SellerPage/sagas';
import shoppingDeliveryPageSagas from '../templates/ShoppingDeliveryPage/sagas';
import selectionPageSagas from '../templates/SelectionPage/sagas';
import shoppingCartPageSagas from '../templates/ShoppingCartPage/sagas';

import {
  getCurrentSessionSuccess,
  getCurrentSessionFailure,
  getCurrentSessionLaunched,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  getUsersEmailSuccess,
  getUsersEmailFailure,
  requestPasswordCodeFailure,
  requestPasswordCodeSuccess,
  signupSuccess,
  signupFailure,
  changePasswordSuccess,
  changePasswordFailure,
  submitNewPasswordSuccess,
  submitNewPasswordFailure,
  updateUserFailure,
  updateUserSuccess,
  firstShippingDeliverySuccess,
  firstShippingDeliveryFailure,
  getPreAuthorizationSuccess,
  getDeliverySimulationSuccess,
  getDeliverySimulationFailure,
  createCardRegistrationSuccess,
  createCardRegistrationFailure,
  validateAndPostCustomerOrderLaunched,
  validateAndPostCustomerOrderSuccess,
  validateAndPostCustomerOrderError,
  searchCustomerOrdersSuccess,
  searchCustomerOrdersFailure,
  searchSellerOrdersSuccess,
  searchSellerOrdersFailure,
  getCustomerOrderSuccess,
  getCustomerOrderFailure,
  newsletterSubscriptionSuccess,
  newsletterSubscriptionFailure,
  getSearchSuccess,
  getSearchFailure,
  contactSuccess,
  contactFailure,
  clientContactSuccess,
  clientContactFailure,
  incidentSuccess,
  incidentFailure,
  openPopup,
  getContentTypeEntriesSuccess,
  getContentTypeEntriesFailure,
} from './reducer';
import {
  translateSignInError,
  translateConfirmForgotPassword,
  translateForgotPassword
} from '../utils/cognito';
import * as countries from "i18n-iso-countries";

// CONFIG
const clientContentful = contentful.createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
});

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const getAlpha3Code = (code) => countries.getAlpha3Code(code, 'en');
// Algolia
const client = algoliasearch(configAlgolia.getAlgoliaAppId(), configAlgolia.getAlgoliaApiKey());
const productIndexes = {
  default: client.initIndex(configAlgolia.getAlgoliaProductIndexName()),
  ascPrice: client.initIndex(configAlgolia.getAlgoliaProductIndexName() + "-AscPrice"),
  descPrice: client.initIndex(configAlgolia.getAlgoliaProductIndexName() + "-DescPrice"),
  descWeight: client.initIndex(configAlgolia.getAlgoliaProductIndexName() + "-DescWeight")
};

const customHeader = {
  'x-api-key': config.apiKey
};

function* getUsersEmail(action) {
  const email = action.payload.formEmail;
  try {
    const usersEmail = yield API.get(config.apiGateway.NAME, `/users?email=${email}`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    yield put(getUsersEmailSuccess(usersEmail));
  } catch (error) {
    console.log(error);
    yield put(getUsersEmailFailure(error));
  }
}

function* getCurrentSession(action) {
  const { fromPath } = action.payload || {};
  try {
    yield Auth.currentSession();
    const userInfo = yield Auth.currentUserInfo();
    const userDynamo = yield API.post(config.apiGateway.NAME, '/sessions', {
      headers: {
        'x-api-key': config.apiKey
      },
      body: {
        email: userInfo.attributes.email,
        role: 'USER'
      }
    });
    yield put(getCurrentSessionSuccess({ userInfo, userDynamo }));
    if (fromPath) {
      yield navigate(fromPath);
    }
  } catch (error) {
    console.log(error);
    yield put(getCurrentSessionFailure());
  }
}

function* doSignIn(action) {
  const { email, password, from } = action.payload;

  try {
    yield Auth.signIn(email, password);
    yield put(loginSuccess());
  } catch (err) {
    yield put(loginFailure(translateSignInError(err.code)));
  }
  yield put(getCurrentSessionLaunched({ fromPath: from || '/' }));
}

function* doSignOut() {
  try {
    yield Auth.signOut();
    yield put(logoutSuccess());
  } catch (err) {
    yield put(logoutFailure());
  }
  yield navigate('/');
}

function* doSignUp(action) {
  const { email, password, from } = action.payload;
  try {
    // yield Auth.signUp({ username: email, password });
    // yield call(doSignIn, { payload: { email, password, redirect: false } });
    const result = yield API.post(config.apiGateway.NAME, `/users`, {
      headers: {
        'x-api-key': config.apiKey
      },
      body: {
        email,
        password
      }
    })
    if (from) {
      yield call(doSignIn, { payload: { email, password, from } });
    } else {
      yield call(doSignIn, { payload: { email, password, redirect: false } });
    }

    yield put(signupSuccess(result));
    // yield put(getCurrentSessionLaunched({ fromPath: '/' }));
  } catch (error) {
    console.log(error);
    yield put(signupFailure(error.code));
  }
}

function* doChangePassword(action) {
  const data = action.payload;
  try {
    const currentUser = yield Auth.currentAuthenticatedUser();
    const result = yield Auth.changePassword(
      currentUser,
      data.oldPassword,
      data.password
    );
    yield put(changePasswordSuccess(result));
  } catch (error) {
    yield put(changePasswordFailure(error));
  }
}

function* doRequestPasswordCode(action) {
  const { email } = action.payload;

  try {
    yield Auth.forgotPassword(email);
    yield put(requestPasswordCodeSuccess());
  } catch (error) {
    yield put(requestPasswordCodeFailure(translateForgotPassword(error.code)));
  }
}

function* doSubmitNewPassword(action) {
  const {
    username, code, password
  } = action.payload;
  try {
    yield Auth.forgotPasswordSubmit(username, code, password);
    yield put(submitNewPasswordSuccess());
  } catch (error) {
    yield put(submitNewPasswordFailure(translateConfirmForgotPassword(error.code)));
  }
}

function* doUpdateUser(action) {
  const { user } = yield select((state) => state.getIn(['root', 'userDynamo']));
  const userId = user.id;
  const body = action.payload;
  body.userId = userId;
  try {
    const apiURL = `/users/${userId}`;
    const params = {
      headers: {
        'x-api-key': config.apiKey
      },
      body: body
    };
    yield API.put(config.apiGateway.NAME, apiURL, params);
    yield put(updateUserSuccess());
    yield put(getCurrentSessionLaunched()); // force to refresh userDynamo
  } catch (error) {
    yield put(updateUserFailure());
  }
}

function* doFirstShippingDelivery(action) {
  const { user } = yield select((state) => state.getIn(['root', 'userDynamo']));
  const userId = user.id;
  const body = action.payload;
  body.userId = userId;

  try {
    const apiURL = `/users/${userId}`;
    const params = {
      headers: {
        'x-api-key': config.apiKey
      },
      body: body
    };
    yield API.put(config.apiGateway.NAME, apiURL, params);
    try {
      const apiURL2 = `/mangopay/createMangoPayNaturalUser`;
      const params2 = {
        headers: {
          'x-api-key': config.apiKey,
        },
        body: {
          userId: userId
        }
      };
      const result = yield API.post(config.apiGateway.NAME, apiURL2, params2);
      yield put(firstShippingDeliverySuccess(result));
      yield put(getCurrentSessionLaunched());
    } catch (error) {
      yield put(firstShippingDeliveryFailure(error))
    }

  } catch (error) {
    yield put(firstShippingDeliveryFailure(error))
  }
}

// Get Preauthorization after paiement
function* getPreAuthorization(action) {
  const { preAuthorizationId } = action.payload;
  try {
    const chosenPreauth = yield mangopay.GetPreAuthorization(preAuthorizationId);

    yield put(getPreAuthorizationSuccess({ preAuthorization: chosenPreauth }));
    yield put(validateAndPostCustomerOrderLaunched({ preAuthorization: chosenPreauth })); // verif preAuth & post customerOrder
  }
  catch (e) {
    console.log(e);
  }
}

// * Permit to create DeliverySimulation and update delivery prices 
function* getDeliverySimulation(action) {
  const { user } = yield select((state) => state.getIn(['root', 'userDynamo']));
  const userId = user?.id;
  const { address, city, zipCode, country, cartItems } = action.payload;
  // Calculate shipping fees
  try {
    let deliveryInformations = {
      address: address,
      zipCode: zipCode,
      city: city,
      country: country,
    };

    yield deliveryInformations.countryCode = getAlpha3Code(country);

    const apiURL = `/deliverySimulations`;
    const params = {
      headers: {
        'x-api-key': config.apiKey
      },
      body: {
        delivery: deliveryInformations,
        cartItems: cartItems,
        customerId: userId
      }
    };

    const deliverySimulations = yield API.post(config.apiGateway.NAME, apiURL, params);

    // Retrieving Delivery fee information by seller and time to ship
    const apiURL2 = `/deliverySimulations/${deliverySimulations.deliverySimulationId}`;
    const params2 = {
      headers: {
        'x-api-key': config.apiKey
      },
    };

    const deliverySimulationsFinal = yield API.get(config.apiGateway.NAME, apiURL2, params2);

    yield put(getDeliverySimulationSuccess(deliverySimulationsFinal));
  } catch (error) {
    yield put(getDeliverySimulationFailure());
  }

}

/**
 * @see https://docs.mangopay.com/endpoints/v2.01/cards#e178_create-a-card-registration
 */
function* doCreateCardRegistration(action) {
  const { user } = yield select((state) => state.getIn(['root', 'userDynamo']));
  let { cardNumber, cardExpirationDate, cardCvx, Amount } = action.payload;

  try {
    const mangopayId = user.mangopayId;

    // TODO : change how we get IpAddress (using cognito api)

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", "https://ipapi.co/ip/", false); // false for synchronous request
    xmlHttp.send(null);
    const IpAddress = xmlHttp.responseText;

    const tokenResult = yield mangopay.CreateToken(mangopayId);
    const postCardResult = yield mangopay.PostCardInfo(tokenResult, cardNumber, cardExpirationDate, cardCvx);
    const putTokenData = yield mangopay.PutTokenData(postCardResult, tokenResult.Id);

    if (putTokenData.Status === "ERROR") {

      yield put(createCardRegistrationFailure(mangopay.translatePaymentError(putTokenData.ResultMessage)));

    }
    else {
      const postPreAuthorization = yield mangopay.PostPreAuthorization(mangopayId, putTokenData.CardId, Amount, IpAddress);

      if (Amount >= 5000) { // 3DSecure is triggered
        if (postPreAuthorization.Status === "SUCCEEDED" || postPreAuthorization.Status === "CREATED") { // Payment is CREATED but WAITING 3dSecure
          if (typeof window !== undefined) {
            const timer = window.setTimeout(() => navigate(postPreAuthorization.SecureModeRedirectURL), 1500);
            window.setTimeout(timer);
          }
        }
        else { // Trigger paiement error and display them in a checkbox
          console.log("ERROR : paiement status = ", postPreAuthorization.ResultMessage)
          yield put(createCardRegistrationFailure(mangopay.translatePaymentError(postPreAuthorization.ResultMessage)));
        }
      }
      else { // 3DSecure isn't triggered
        if (postPreAuthorization.Status === "CREATED" && postPreAuthorization.SecureModeRedirectURL) {
          if (typeof window !== undefined) {
            const timer = window.setTimeout(() => navigate(postPreAuthorization.SecureModeRedirectURL), 1500);
            window.setTimeout(timer);
          }


        } else if (postPreAuthorization.Status === "SUCCEEDED" && postPreAuthorization.SecureModeRedirectURL) { // There is no error during payment
          let currentURL = typeof window !== 'undefined' ? window.location.href : '';
          if (currentURL.endsWith('/')) {
            currentURL = currentURL.slice(0, -1);
          }
          if (typeof window !== undefined) {
            const timer = window.setTimeout(() => navigate(currentURL + "/paymentStatus?preAuthorizationId=" + postPreAuthorization.Id), 1500);
            window.setTimeout(timer);
          }

        }
        else { // Trigger paiement error and display them
          console.log("ERROR : paiement status = ", postPreAuthorization.ResultMessage)
          yield put(createCardRegistrationFailure(mangopay.translatePaymentError(postPreAuthorization.ResultMessage)));
        }
      }
    }
  } catch (error) {
    yield put(createCardRegistrationFailure(mangopay.translatePaymentError(error.toString())));
  }
}

function* doPostCustomerOrder(action) {
  const { preAuthorization } = action.payload;
  let customerOrder;
  if (typeof window !== undefined) {
    customerOrder = JSON.parse(localStorage.getItem("customerOrder"));
  }

  if (preAuthorization?.Status === "SUCCEEDED" && customerOrder) {
    try {
      // POST API
      customerOrder.payment.infos = { preAuthorizationId: preAuthorization?.Id };
      yield API.post(config.apiGateway.NAME, `/customerOrders`, {
        headers: {
          'x-api-key': config.apiKey
        },
        body: customerOrder
      })
      yield put(validateAndPostCustomerOrderSuccess());

      // clear customerOrder saved into localStorage
      if (typeof window !== undefined) {
        window.localStorage.removeItem('customerOrder');
      }

    } catch (error) {
      yield put(validateAndPostCustomerOrderError())
    }
    yield put(createCardRegistrationSuccess());

  } else if (preAuthorization?.Status !== "SUCCEEDED" && customerOrder) {
    if (preAuthorization?.ResultMessage) {
      yield put(createCardRegistrationFailure(mangopay.translatePaymentError(preAuthorization?.ResultMessage)));
    }
    yield put(validateAndPostCustomerOrderError())
  }

};

function* searchCustomerOrders() {
  const { user } = yield select((state) => state.getIn(['root', 'userDynamo']));
  const userId = user?.id;
  try {
    const customerOrders = yield API.get(config.apiGateway.NAME, `/customerOrders?userId=${userId}`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    yield put(searchCustomerOrdersSuccess(customerOrders));
  } catch (error) {
    yield put(searchCustomerOrdersFailure());
  }
}

function* searchSellerOrders(action) {
  const { customerOrderId } = action.payload;
  const { user } = yield select((state) => state.getIn(['root', 'userDynamo']));
  const userId = user?.id;
  try {
    const sellerOrders = yield API.get(config.apiGateway.NAME, `/sellerOrders?userId=${userId}&customerOrderId=${customerOrderId}`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    yield put(searchSellerOrdersSuccess(sellerOrders));
  } catch (error) {
    yield put(searchSellerOrdersFailure());
  }
}

function* getCustomerOrder(action) {
  const { customerOrderId } = action.payload;
  try {
    const customerOrder = yield API.get(config.apiGateway.NAME, `/customerOrders/${customerOrderId}`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    yield put(getCustomerOrderSuccess(customerOrder));
  } catch (error) {
    yield put(getCustomerOrderFailure());
  }
}

function* newsletterSubscription(action) {
  const data = action.payload.emailNewsletter;
  try {
    const result = yield API.post(config.apiGateway.NAME, `/newsletterSubscription`, {
      headers: {
        'x-api-key': config.apiKey
      },
      body: {
        email: data
      }
    })
    yield put(newsletterSubscriptionSuccess(result));
  } catch (error) {
    console.log(error);
    yield put(newsletterSubscriptionFailure(error));
  }
}

// SEARCH
function* doGetSearchResults(action) {
  try {
    const {
      value,
      searchLocale,
      currentPath,
      orderType,
      searchPage,
      searchCategory,
      searchPriceMin,
      searchPriceMax,
      searchBySeller,
      searchFacets,
      sellersOfQuery,
      categoryOfQuery,
    } = action.payload || {};

    let filterIndexName = "default";
    let filtersParam = `locale:${searchLocale}`;
    if (searchPriceMin && searchPriceMax) {
      filtersParam += ` AND price:${searchPriceMin} TO ${searchPriceMax}`
    } else if (searchPriceMax && !searchPriceMin) {
      filtersParam += ` AND price:0 TO ${searchPriceMax}`
    }
    if (searchBySeller) {
      if (searchBySeller.length > 0) {
        filtersParam += ` AND (`
        searchBySeller.forEach((element, index) => {
          let sellerName = element.replace(/'/g, "\\'"); // escape quote with antislash
          if (index === 0) {
            filtersParam += `sellerName:'${sellerName}'`
          } else {
            filtersParam += ` OR sellerName:'${sellerName}'`
          }
        });
        filtersParam += `)`
      }
    }
    if (searchFacets) {
      searchFacets.forEach((facet) => {
        if (facet.value.length > 0) {
          filtersParam += ` AND (`
          facet.value.forEach((element, index) => {
            if (index === 0) {
              filtersParam += `${facet.code}:'${element}'`
            } else {
              filtersParam += ` OR ${facet.code}:'${element}'`
            }
          });
          filtersParam += `)`
        }
      })
    }

    if (searchCategory && searchCategory.length > 0) {
      filtersParam += ` AND (`
      searchCategory.forEach((element, index) => {
        if (index === 0) {
          filtersParam += `partnerCategory:'${element}'`
        } else {
          filtersParam += ` OR partnerCategory:'${element}'`
        }
      });
      filtersParam += `)`
    }

    if (orderType && orderType === "asc-price") {
      filterIndexName = "ascPrice";
    }
    if (orderType && orderType === "desc-price") {
      filterIndexName = "descPrice";
    }
    if (orderType && orderType === "desc-weight") {
      filterIndexName = "descWeight";
    }

    const results = yield productIndexes[filterIndexName].search(value, {
      page: searchPage,
      hitsPerPage: configAlgolia.getProductsPerPageInPageLists(),
      filters: filtersParam,
    });

    // At first search : we save an array with query's sellers and query's category
    let sellers = [];
    let category = []
    if (!sellersOfQuery && !categoryOfQuery) { // first search : sellersOfQuery and categoryOfQuery is undefined
      const sellersGlobal = [...new Set(results.hits.map(item => item.sellerName))]; //  Filters sellers to avoid double values in lateral filter section
      sellersGlobal.forEach((seller) => {
        sellers.push({
          node: {
            corporateName: seller
          }
        })
      })

      category = [...new Set(results.hits.map(item => item.partnerCategory))]; //  Filters category to avoid double values in lateral filter section
    }

    yield put(getSearchSuccess({
      query: value,
      productContents: results.hits,
      page: results.page,
      lastPage: results.nbPages,
      sellers: (sellersOfQuery ? sellersOfQuery : sellers),
      category: (categoryOfQuery ? categoryOfQuery : category)
    }));

    if (currentPath !== `/${searchLocale}/resultat`) {
      navigate(`/${searchLocale}/resultat`)
    }
  } catch (e) {
    yield put(getSearchFailure(e));

  }
}
// contact without connection
function* contact(action) {
  const body = JSON.stringify(action.payload);
  try {
    const result = yield fetch(`${config.apiGateway.URL}/user/message`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': process.env.GATSBY_API_KEY,
      },
      body
    });
    yield put(contactSuccess(result));
  } catch (error) {
    console.log(error);
    yield put(contactFailure());
  }
}
// contact from account page
function* clientContact(action) {
  const { user } = yield select((state) => state.getIn(['root', 'userDynamo']));
  const userId = user.id;
  const body = action.payload.formValue;
  try {
    const contents = yield API.post(config.apiGateway.NAME, `/user/${userId}/messages`, {
      headers: customHeader,
      body
    });
    yield put(clientContactSuccess());
  } catch (error) {
    console.log(error);
    yield put(clientContactFailure());
  }
}

// incident declaration 
function* incident(action) {
  const body = action.payload;
  const sellerOrderId = body.id;
  try {
    yield API.put(config.apiGateway.NAME, `/sellerOrders/incident/${sellerOrderId}`, {
      headers: customHeader,
      body
    });
    yield put(incidentSuccess());
  } catch (error) {
    console.log(error);
    yield put(incidentFailure());
  }
}

// GET CONTENT TYPE ENTRIES
/**
 * @see https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/order/query-entries/console/js
 */
function* doGetContentTypeEntries(action) {
  const { id, date, limit } = action.payload;
  try {
    let params = {
      content_type: id,
    }
    if (date) {
      params = {
        ...params,
        'fields.date[gte]': date,
        order: 'fields.date'
      }
    }
    if (limit) {
      params = {
        ...params,
        limit,
      }
    }
    const results = yield clientContentful.getEntries(params)
    yield put(getContentTypeEntriesSuccess(results.items))
  } catch (error) {
    yield put(getContentTypeEntriesFailure())
    yield put(openPopup({ open: true, message: "Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement", error: true, popupId: "get content type entries" }))
  }
}

export default function* leadCreationSaga() {
  yield all([
    takeLatest('root/getCurrentSessionLaunched', getCurrentSession),
    takeLatest('root/loginLaunched', doSignIn),
    takeLatest('root/logoutLaunched', doSignOut),
    takeLatest('root/signupLaunched', doSignUp),
    takeLatest('root/changePasswordLaunched', doChangePassword),
    takeLatest('root/requestPasswordCodeLaunched', doRequestPasswordCode),
    takeLatest('root/submitNewPasswordLaunched', doSubmitNewPassword),
    takeLatest('root/updateUserLaunched', doUpdateUser),
    takeLatest('root/firstShippingDeliveryLaunched', doFirstShippingDelivery),
    takeLatest('root/getDeliverySimulationLaunched', getDeliverySimulation),
    takeLatest('root/getPreAuthorizationLaunched', getPreAuthorization),
    takeLatest('root/createCardRegistrationLaunched', doCreateCardRegistration),
    takeLatest('root/validateAndPostCustomerOrderLaunched', doPostCustomerOrder),
    takeLatest('root/searchCustomerOrdersLaunched', searchCustomerOrders),
    takeLatest('root/searchSellerOrdersLaunched', searchSellerOrders),
    takeLatest('root/getCustomerOrderLaunched', getCustomerOrder),
    takeLatest('root/newsletterSubscriptionLaunched', newsletterSubscription),
    takeLatest('root/getSearchLaunched', doGetSearchResults),
    takeLatest('root/getContentTypeEntriesLaunched', doGetContentTypeEntries),
    takeLatest('root/contactLaunched', contact),
    takeLatest('root/clientContactLaunched', clientContact),
    takeLatest('root/incidentLaunched', incident),
    takeLatest('root/getUsersEmailLaunched', getUsersEmail),
    pageListSagas(),
    sellerPageSagas(),
    shoppingDeliveryPageSagas(),
    selectionPageSagas(),
    shoppingCartPageSagas(),
  ]);
}
