import Immutable from 'immutable';
import { createSlice } from "@reduxjs/toolkit";

const initialState = Immutable.Map({
  isLoading: false,
  offerChecked: null,
  errors: null,
});

const { actions, reducer } = createSlice({
  slice: 'shoppingCartPage',
  name: 'shoppingCartPage',
  initialState,
  reducers: {
    checkOfferLaunch: (state, action) => state
      .set('isLoading', true)
      .set('offerChecked', null)
      .set('errors', null),

    checkOfferSuccess: (state, action) => state
      .set('isLoading', false)
      .set('offerChecked', action.payload)
      .set('errors', false),

    checkOfferFailure: (state, action) => state
      .set('isLoading', false)
      .set('offerChecked', null)
      .set('errors', true),
  }
});

export const {
  checkOfferLaunch,
  checkOfferSuccess,
  checkOfferFailure,
} = actions;

export default reducer;
