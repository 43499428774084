import React from 'react'
import { createStore, applyMiddleware, compose } from 'redux';
import Amplify from 'aws-amplify';
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga';
import { amplifyConfig } from '../utils/amplify';

import createRootReducer from './configureStore';
import root from './sagas'

// CartProvider is placed here to be available throughout the application
import { CartProvider } from 'react-use-cart';

const sagaMiddleware = createSagaMiddleware();
Amplify.configure(amplifyConfig);


export default function configureStore({ element }) {
  let store;
  if (process.env.NODE_ENV === "development") {
    const composeEnhancers = compose;
    store = createStore(
      createRootReducer(),
      composeEnhancers(applyMiddleware(sagaMiddleware))
    );
  } else {
    store = createStore(
      createRootReducer(),
      compose(applyMiddleware(sagaMiddleware))
    );
  }

  sagaMiddleware.run(root);

  return (
    <Provider store={store}>
      <CartProvider>
        {element}
      </CartProvider>
    </Provider>
  );
}
