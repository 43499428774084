import Immutable from 'immutable';
import { createSlice } from "@reduxjs/toolkit";

const initialState = Immutable.Map({
  isLoading: false,
  productContents: null,
  page: null,
  lastPage: null,
  errors: null,
  snackBarLoading: false,
  snackBarOpen: false,
  snackBarMessage: null,
  snackBarError: false,
  deliveryZones: null,
  getDeliveryZonesLoading: false,
  getDeliveryZonesError: null,
  getSellerDeliveryModesLoading: false,
  getSellerDeliveryModesError: null,
  sellerDeliveryModes: null
});

const { actions, reducer } = createSlice({
  slice: 'sellerPage',
  name: 'sellerPage',
  initialState,
  reducers: {
    searchLaunched: (state, action) => state
      .set('isLoading', true)
      .set('productContents', null)
      .set('page', null)
      .set('lastPage', null),
    // TODO errors => null
    searchSuccess: (state, action) => state
      .set('isLoading', false)
      .set('productContents', action.payload && action.payload.productContents)
      .set('page', action.payload.page)
      .set('lastPage', action.payload.lastPage),
    searchFailure: (state, action) => state
      .set('isLoading', false)
      .set('errors', [].concat(action.payload && action.payload.errors))
      .set('page', null)
      .set('lastPage', null),
    // SnackBar
    sellerLaunchedSnackBar: (state, action) => state
      .set('snackBarLoading', true),
    openSnackBar: (state, action) => state
      .set('snackBarLoading', false)
      .set('snackBarOpen', true)
      .set('snackBarMessage', action.payload.message)
      .set('snackBarError', action.payload.error),
    closeSnackBar: (state, action) => state
      .set('snackBarOpen', false),
    clearSnackBar: (state, action) => state
      .set('snackBarMessage', null)
      .set('snackBarError', false),

    // Get delivery zone
    getDeliveryZonesLaunched: (state, action) => state
      .set('getDeliveryZonesLoading', true)
      .set('deliveryZones', null)
      .set('getDeliveryZonesError', null),
    getDeliveryZonesSuccess: (state, action) => state
      .set('getDeliveryZonesLoading', false)
      .set('deliveryZones', action.payload)
      .set('getDeliveryZonesError', null),
    getDeliveryZonesFailure: (state, action) => state
      .set('getDeliveryZonesLoading', false)
      .set('deliveryZones', null)
      .set('getDeliveryZonesError', action.payload),

    // Get seller delivery modes
    getSellerDeliveryModesLaunched: (state, action) => state
      .set('getSellerDeliveryModesLoading', true)
      .set('sellerDeliveryModes', null)
      .set('getSellerDeliveryModesError', null),
    getSellerDeliveryModesSuccess: (state, action) => state
      .set('getSellerDeliveryModesLoading', false)
      .set('sellerDeliveryModes', action.payload)
      .set('getSellerDeliveryModesError', null),
    getSellerDeliveryModesFailure: (state, action) => state
      .set('getSellerDeliveryModesLoading', false)
      .set('sellerDeliveryModes', null)
      .set('getSellerDeliveryModesError', action.payload),
  }
});

export const {
  searchLaunched,
  searchSuccess,
  searchFailure,
  sellerLaunchedSnackBar,
  openSnackBar,
  closeSnackBar,
  clearSnackBar,
  getDeliveryZonesLaunched,
  getDeliveryZonesSuccess,
  getDeliveryZonesFailure,
  getSellerDeliveryModesLaunched,
  getSellerDeliveryModesSuccess,
  getSellerDeliveryModesFailure,
} = actions;

export default reducer;
