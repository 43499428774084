import Immutable from 'immutable';
import { createSlice } from "@reduxjs/toolkit";

const initialState = Immutable.Map({
  isLoading: false,
  relayPointContents: null,
  RPstatutCode: null,
  errors: null,
  detailIssLoading: false,
  relayPointDetail: null,
  RPDetailStatutCode: null,
  errorsDetail: null,
  cityLoading: false,
  citySuggestion: null,
  CSstatutCode: null,
  cityErrors: null
});

const { actions, reducer } = createSlice({
  slice: 'shoppingDeliveryPage',
  name: 'shoppingDeliveryPage',
  initialState,
  reducers: {
    getRelayPointLaunched: (state, action) => state
      .set('isLoading', true)
      .set('relayPointContents', null)
      .set('RPstatutCode', null)
      .set('errors', null),
    getRelayPointSuccess: (state, action) => state
      .set('isLoading', false)
      .set('relayPointContents', action.payload.relayPoints)
      .set('RPstatutCode', action.payload.statutCode)
      .set('errors', null),
    getRelayPointFailure: (state, action) => state
      .set('isLoading', false)
      .set('relayPointContents', null)
      .set('RPstatutCode', null)
      .set('errors', action.payload),

    getRelayPointDetailLaunched: (state, action) => state
      .set('detailIssLoading', true)
      .set('relayPointDetail', null)
      .set('RPDetailStatutCode', null)
      .set('errorsDetail', null),
    getRelayPointDetailSuccess: (state, action) => state
      .set('detailIssLoading', false)
      .set('relayPointDetail', action.payload.relayPoints)
      .set('RPDetailStatutCode', action.payload.statutCode)
      .set('errorsDetail', null),
    getRelayPointDetailFailure: (state, action) => state
      .set('detailIssLoading', false)
      .set('relayPointDetail', null)
      .set('RPDetailStatutCode', null)
      .set('errorsDetail', action.payload),

    getCitySuggestionLaunched: (state, action) => state
      .set('cityLoading', true)
      .set('citySuggestion', null)
      .set('CSstatutCode', null)
      .set('cityErrors', null),
    getCitySuggestionSuccess: (state, action) => state
      .set('cityLoading', false)
      .set('citySuggestion', action.payload.cityWithZipcodeResponse)
      .set('CSstatutCode', action.payload.statutCode)
      .set('cityErrors', null),
    getCitySuggestionFailure: (state, action) => state
      .set('cityLoading', false)
      .set('citySuggestion', null)
      .set('CSstatutCode', null)
      .set('cityErrors', action.payload),
  }
});

export const {
  getRelayPointLaunched,
  getRelayPointSuccess,
  getRelayPointFailure,
  getRelayPointDetailLaunched,
  getRelayPointDetailSuccess,
  getRelayPointDetailFailure,
  getCitySuggestionLaunched,
  getCitySuggestionSuccess,
  getCitySuggestionFailure
} = actions;

export default reducer;