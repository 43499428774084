// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-account-page-index-js": () => import("./../../../src/templates/AccountPage/index.js" /* webpackChunkName: "component---src-templates-account-page-index-js" */),
  "component---src-templates-advice-page-index-js": () => import("./../../../src/templates/AdvicePage/index.js" /* webpackChunkName: "component---src-templates-advice-page-index-js" */),
  "component---src-templates-cgv-page-index-js": () => import("./../../../src/templates/CGVPage/index.js" /* webpackChunkName: "component---src-templates-cgv-page-index-js" */),
  "component---src-templates-contact-page-index-js": () => import("./../../../src/templates/ContactPage/index.js" /* webpackChunkName: "component---src-templates-contact-page-index-js" */),
  "component---src-templates-faq-page-index-js": () => import("./../../../src/templates/FaqPage/index.js" /* webpackChunkName: "component---src-templates-faq-page-index-js" */),
  "component---src-templates-home-page-index-js": () => import("./../../../src/templates/HomePage/index.js" /* webpackChunkName: "component---src-templates-home-page-index-js" */),
  "component---src-templates-legal-page-index-js": () => import("./../../../src/templates/LegalPage/index.js" /* webpackChunkName: "component---src-templates-legal-page-index-js" */),
  "component---src-templates-login-page-index-js": () => import("./../../../src/templates/LoginPage/index.js" /* webpackChunkName: "component---src-templates-login-page-index-js" */),
  "component---src-templates-not-found-page-index-js": () => import("./../../../src/templates/NotFoundPage/index.js" /* webpackChunkName: "component---src-templates-not-found-page-index-js" */),
  "component---src-templates-order-page-index-js": () => import("./../../../src/templates/OrderPage/index.js" /* webpackChunkName: "component---src-templates-order-page-index-js" */),
  "component---src-templates-page-list-index-js": () => import("./../../../src/templates/PageList/index.js" /* webpackChunkName: "component---src-templates-page-list-index-js" */),
  "component---src-templates-password-page-index-js": () => import("./../../../src/templates/PasswordPage/index.js" /* webpackChunkName: "component---src-templates-password-page-index-js" */),
  "component---src-templates-payment-status-page-index-js": () => import("./../../../src/templates/PaymentStatusPage/index.js" /* webpackChunkName: "component---src-templates-payment-status-page-index-js" */),
  "component---src-templates-product-page-index-js": () => import("./../../../src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */),
  "component---src-templates-salon-page-index-js": () => import("./../../../src/templates/SalonPage/index.js" /* webpackChunkName: "component---src-templates-salon-page-index-js" */),
  "component---src-templates-search-result-index-js": () => import("./../../../src/templates/SearchResult/index.js" /* webpackChunkName: "component---src-templates-search-result-index-js" */),
  "component---src-templates-selection-page-index-js": () => import("./../../../src/templates/SelectionPage/index.js" /* webpackChunkName: "component---src-templates-selection-page-index-js" */),
  "component---src-templates-seller-page-index-js": () => import("./../../../src/templates/SellerPage/index.js" /* webpackChunkName: "component---src-templates-seller-page-index-js" */),
  "component---src-templates-shopping-cart-page-index-js": () => import("./../../../src/templates/ShoppingCartPage/index.js" /* webpackChunkName: "component---src-templates-shopping-cart-page-index-js" */),
  "component---src-templates-shopping-delivery-page-index-js": () => import("./../../../src/templates/ShoppingDeliveryPage/index.js" /* webpackChunkName: "component---src-templates-shopping-delivery-page-index-js" */),
  "component---src-templates-signup-page-index-js": () => import("./../../../src/templates/SignupPage/index.js" /* webpackChunkName: "component---src-templates-signup-page-index-js" */),
  "component---src-templates-single-advice-page-index-js": () => import("./../../../src/templates/SingleAdvicePage/index.js" /* webpackChunkName: "component---src-templates-single-advice-page-index-js" */)
}

