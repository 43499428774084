const config = {
  AWS_REGION: process.env.GATSBY_AWS_REGION,
  apiGateway: {
    URL: process.env.GATSBY_APIGATEWAY_URL,
    NAME: process.env.GATSBY_API_NAME
  },
  cognito: {
    USER_POOL_ID: process.env.GATSBY_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.GATSBY_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID
  },
  apiKey: process.env.GATSBY_API_KEY
};

const amplifyConfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.AWS_REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  API: {
    endpoints: [
      {
        name: process.env.GATSBY_API_NAME,
        endpoint: process.env.GATSBY_APIGATEWAY_URL,
        region: process.env.GATSBY_AWS_REGION
      }
    ]
  },
}

export { config, amplifyConfig };