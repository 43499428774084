import { combineReducers } from 'redux-immutable';
import appReducer from './reducer';
import pageListReducer from '../templates/PageList/reducer';
import sellerPageReducer from '../templates/SellerPage/reducer';
import shoppingDeliveryPageReducer from '../templates/ShoppingDeliveryPage/reducer';
import selectionPageReducer from '../templates/SelectionPage/reducer';
import shoppingCartPageReducer from '../templates/ShoppingCartPage/reducer';

const history = () => combineReducers({
  root: appReducer,
  pageList: pageListReducer,
  sellerPage: sellerPageReducer,
  shoppingDeliveryPage: shoppingDeliveryPageReducer,
  selectionPage: selectionPageReducer,
  shoppingCartPage: shoppingCartPageReducer,
});

export default history;
