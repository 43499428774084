module.exports.getProductsPerPageInPageLists = () => {
  return Number(process.env.GATSBY_PRODUCTS_PER_PAGE_IN_PAGE_LISTS) || 32;
}

module.exports.getAPIUrl = () => {
  return process.env.GATSBY_API_URL;
}

module.exports.getAPIKey = () => {
  return process.env.GATSBY_API_KEY;
}

module.exports.getAlgoliaAppId = () => {
  return process.env.GATSBY_ALGOLIA_APP_ID;
}

module.exports.getAlgoliaApiKey = () => {
  return process.env.GATSBY_ALGOLIA_SEARCHONLY_API_KEY;
}

module.exports.getAlgoliaProductIndexName = () => {
  return process.env.GATSBY_ALGOLIA_PRODUCT_INDEX_NAME;
}
