import Immutable from 'immutable';
import { createSlice } from "@reduxjs/toolkit";

const initialState = Immutable.Map({
  isLoading: false,
  productContents: null,
  page: null,
  lastPage: null,
  errors: null,
  snackBarLoading: false,
  snackBarOpen: false,
  snackBarMessage: null,
  snackBarError: false
});

const { actions, reducer } = createSlice({
  slice: 'pageList',
  name: 'pageList',
  initialState,
  reducers: {
    searchLaunched: (state, action) => state
      .set('isLoading', true)
      .set('productContents', null)
      .set('page', null)
      .set('lastPage', null),

    // TODO errors => null
    searchSuccess: (state, action) => state
      .set('isLoading', false)
      .set('productContents', action.payload && action.payload.productContents)
      .set('page', action.payload.page)
      .set('lastPage', action.payload.lastPage),

    searchFailure: (state, action) => state
      .set('isLoading', false)
      .set('errors', [].concat(action.payload && action.payload.errors))
      .set('page', null)
      .set('lastPage', null),

    // SnackBar
    launchedSnackBar: (state, action) => state
      .set('snackBarLoading', true),
    openSnackBar: (state, action) => state
      .set('snackBarLoading', false)
      .set('snackBarOpen', true)
      .set('snackBarMessage', action.payload.message)
      .set('snackBarError', action.payload.error),
    closeSnackBar: (state, action) => state
      .set('snackBarOpen', false),
    clearSnackBar: (state, action) => state
      .set('snackBarMessage', null)
      .set('snackBarError', false),
  }
});

export const {
  searchLaunched,
  searchSuccess,
  searchFailure,
  launchedSnackBar,
  openSnackBar,
  closeSnackBar,
  clearSnackBar,
} = actions;

export default reducer;