import { all, put, takeLatest } from 'redux-saga/effects';
import algoliasearch from 'algoliasearch';
import config from '../../utils/libs/config';
import {
  searchSuccess,
  searchFailure,
} from './reducer';
const client = algoliasearch(config.getAlgoliaAppId(), config.getAlgoliaApiKey());
const productIndexes = {
  default: client.initIndex(config.getAlgoliaProductIndexName()),
  ascPrice: client.initIndex(config.getAlgoliaProductIndexName() + "-AscPrice"),
  descPrice: client.initIndex(config.getAlgoliaProductIndexName() + "-DescPrice"),
  descWeight: client.initIndex(config.getAlgoliaProductIndexName() + "-DescWeight")
};

function* doSelectionPageSearch(action) {
  try {
    const {
      orderType,
      searchPage,
      searchLocale,
      searchPriceMin,
      searchPriceMax,
    } = action.payload || {};

    let filterIndexName = "default";
    let filtersParam = `locale:${searchLocale} AND flapSelectionZawema.fr:true`;
    if (searchPriceMin && searchPriceMax) {
      filtersParam += ` AND price:${searchPriceMin} TO ${searchPriceMax}`
    } else if (searchPriceMax && !searchPriceMin) {
      filtersParam += ` AND price:0 TO ${searchPriceMax}`
    }
    if (orderType && orderType === "asc-price") {
      filterIndexName = "ascPrice";
    }
    if (orderType && orderType === "desc-price") {
      filterIndexName = "descPrice";
    }
    if (orderType && orderType === "desc-weight") {
      filterIndexName = "descWeight";
    }
    const results = yield productIndexes[filterIndexName].search("", {
      page: searchPage,
      hitsPerPage: config.getProductsPerPageInPageLists(),
      filters: filtersParam,
    });
    yield put(searchSuccess({
      productContents: results.hits,
      page: results.page,
      lastPage: results.nbPages,
    }));
  } catch (e) {
    yield put(searchFailure(e));
  }
}

export default function* leadCreationSaga() {
  yield all([
    takeLatest('selectionPage/filterSelectionPageLaunch', doSelectionPageSearch),
  ]);
}