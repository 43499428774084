const translateSignInError = (errorCode) => {
  switch (errorCode) {
    case 'UserNotFoundException':
      return 'Aucun compte avec cet email n\'existe, veuillez vérifier votre email.';
    case 'NotAuthorizedException':
      return 'Mot de passe incorrect.';
    case 'FormNotComplete':
      return 'Veuillez saisir votre email et mot de passe.';
    default:
      return 'Erreur';
  }
};

const translateForgotPassword = (errorCode) => {
  switch (errorCode) {
    case 'NetworkError':
      return 'Veuillez vérifier votre connection internet.';
    case 'UserNotFoundException':
      return 'Aucun compte avec cet email n\'existe, veuillez vérifier votre email.';
    case 'FormNotComplete':
      return 'Veuillez saisir votre email.';
    case 'LimitExceededException':
    case 'TooManyRequestsException':
      return 'Limite de tentatives dépassé, veuillez reessayer plus tard.';
    default:
      return 'Erreur';
  }
};

const translateConfirmForgotPassword = (errorCode) => {
  switch (errorCode) {
    case 'PasswordDoNotMatch':
      return 'Vos mot de passe ne correspondent pas.';
    case 'CodeMismatchException':
      return 'Code incorrect.';
    case 'ExpiredCodeException':
      return 'Code expiré.';
    case 'NetworkError':
      return 'Veuillez vérifier votre connection internet.';
    case 'FormNotComplete':
      return 'Veuillez completer tous les champs requis.';
    case 'LimitExceededException':
    case 'TooManyRequestsException':
      return 'Limite de tentatives dépassé, veuillez reessayer plus tard.';
    default:
      return 'Erreur';
  }
};

const translateErrorChangePassword = (errorCode) => {
  switch (errorCode) {
    case 'LimitExceededException':
      return 'Limite de tentatives dépassée, veuillez réessayer plus tard.';
    case 'InvalidPasswordException':
      return 'Mot de passe invalide, veuillez réessayer.';
    case 'NotAuthorizedException':
      return 'Echec : ancien mot de passe incorrect !';
    case 'PasswordResetRequiredException':
      return 'Une réinitialisation du mot de passe est requise.';
    case 'ResourceNotFoundException':
      return 'Une erreur est survenue, veuillez réessayer plus tard.';
    case 'TooManyRequestsException':
      return 'Limite de tentatives dépassée, veuillez réessayer plus tard.';
    case 'UserNotConfirmedException':
      return 'Nous n\'avons pas pu vous identifier, veuillez réessayer plus tard.';
    default:
      return 'Une erreur inconnue est survenue.'
  }
}

export {
  translateSignInError,
  translateForgotPassword,
  translateConfirmForgotPassword,
  translateErrorChangePassword
};