import { all, put, takeLatest } from 'redux-saga/effects';
import { API } from 'aws-amplify';
import { config } from '../../utils/amplify';
import {
  checkOfferSuccess,
  checkOfferFailure,
} from './reducer';


function* getShoppingCartPageChecked(action) {
  const cartOfferIds = action.payload;
  try {
    const offerChecked = yield API.post(config.apiGateway.NAME, `/checkCartOffer`, {
      headers: {
        'x-api-key': config.apiKey,
      },
      body: cartOfferIds
    });
    yield put(checkOfferSuccess(offerChecked));
  } catch (error) {
    yield put(checkOfferFailure(error));
  }
}

export default function* leadCreationSaga() {
  yield all([
    takeLatest('shoppingCartPage/checkOfferLaunch', getShoppingCartPageChecked),
  ]);
}