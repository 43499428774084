import Immutable from 'immutable';
import { createSlice } from "@reduxjs/toolkit";

const initialState = Immutable.Map({
  isLoading: false,
  productContents: null,
  page: null,
  lastPage: null,
  errors: null,
  snackBarLoading: false,
  snackBarOpen: false,
  snackBarMessage: null,
  snackBarError: false
});

const { actions, reducer } = createSlice({
  slice: 'selectionPage',
  name: 'selectionPage',
  initialState,
  reducers: {
    filterSelectionPageLaunch: (state, action) => state
      .set('isLoading', true)
      .set('productContents', null)
      .set('page', null)
      .set('lastPage', null),

    // TODO errors => null
    searchSuccess: (state, action) => state
      .set('isLoading', false)
      .set('productContents', action.payload && action.payload.productContents)
      .set('page', action.payload.page)
      .set('lastPage', action.payload.lastPage),

    searchFailure: (state, action) => state
      .set('isLoading', false)
      .set('errors', [].concat(action.payload && action.payload.errors))
      .set('page', null)
      .set('lastPage', null),
  }
});

export const {
  filterSelectionPageLaunch,
  searchSuccess,
  searchFailure,
} = actions;

export default reducer;
