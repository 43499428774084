import Immutable from 'immutable';
import { createSlice } from "@reduxjs/toolkit";
import { SEARCH_SKIP } from '../utils/constants';

const initialState = Immutable.Map({
  isAuthenticated: false,
  isAuthenticating: true,
  userDynamo: null,
  loginLoading: false,
  loginErrorMessage: null,
  logoutLoading: false,
  signupLoading: false,
  signupErrorMessage: null,
  changePasswordLoading: false,
  changePasswordSuccessMessage: null,
  changePasswordErrorMessage: null,
  //registration error message
  getUsersEmailLoading: false,
  getUsersEmailError: null,
  usersEmail: null,
  //
  requestCodeLoading: false,
  requestCodeErrorMessage: null,
  submitPasswordLoading: false,
  submitPasswordErrorMessage: null,
  submitPasswordSuccessMessage: null,
  getPreAuthorizationLoading: null,
  getPreAuthorizationId: null,
  getPreAuthorizationContent: null,
  getPreAuthorizationErrorMessage: null,
  getDeliverySimulationLoading: false,
  getDeliverySimulationContent: null,
  getDeliverySimulationErrorMessage: null,
  forgotPasswordStep: 1,
  updateUserLoading: false,
  updateUserSuccess: null,
  updateUserErrorMessage: null,
  naturalUserMangopayLoading: false,
  naturalUserMangopayContent: null,
  naturalUserMangopayError: null,
  searchCustomerOrdersLoading: false,
  searchCustomerOrdersSuccess: null,
  searchCustomerOrdersFailure: null,
  allCustomerOrders: null,
  searchSellerOrdersLoading: false,
  searchSellerOrdersSuccess: null,
  searchSellerOrdersFailure: null,
  sellerOrders: null,
  getCustomerOrderLoading: false,
  getCustomerOrderSuccess: null,
  getCustomerOrderFailure: null,
  customerOrder: null,
  newsletterSubscriptionLoading: false,
  newsletterSubscriptionErrorMessage: null,
  searchValue: null,
  query: null,
  sellers: [],
  category: [],
  isLoading: false,
  searchResults: [],
  searchErrorMessage: null,
  searchSkip: SEARCH_SKIP,
  page: null,
  lastPage: null,
  contactSuccess: null,
  contactLoading: false,
  contactError: null,
  clientContactLoading: false,
  clientContactSuccessState: null,
  clientContactErrorMessage: null,
  incidentLoading: false,
  incidentSuccess: null,
  incidentError: null,
  errorModale: false,
  popupOpen: false,
  popupMessage: null,
  popupError: false,
  popupId: null,
  contentTypeEntries: [],
  createCardRegistrationLoading: false,
  createCardRegistrationErrorMessage: null,
  postCustomerOrderLoading: false,
  postCustomerOrderError: null,
  postCustomerOrdeSuccess: null
});

const { actions, reducer } = createSlice({
  slice: 'root',
  name: 'root',
  initialState,
  reducers: {
    // GETCURRENT
    getCurrentSessionLaunched: (state, action) => state
      .set('isAuthenticating', true)
      .set('isAuthenticated', false)
      .set('userInfo', null)
      .set('userDynamo', null),
    getCurrentSessionSuccess: (state, action) => state
      .set('isAuthenticating', false)
      .set('isAuthenticated', true)
      .set('userInfo', action.payload.userInfo)
      .set('userDynamo', action.payload.userDynamo),
    getCurrentSessionFailure: (state, action) => state
      .set('isAuthenticating', false)
      .set('isAuthenticated', false)
      .set('userInfo', null)
      .set('userDynamo', null),
    // LOGIN
    loginLaunched: (state, action) => state
      .set('loginLoading', true)
      .set('loginErrorMessage', null),
    loginSuccess: (state, action) => state
      .set('loginLoading', false)
      .set('loginErrorMessage', null),
    loginFailure: (state, action) => state
      .set('loginLoading', false)
      .set('loginErrorMessage', action.payload),
    // LOGOUT
    logoutLaunched: (state, action) => state
      .set('logoutLoading', true),
    logoutSuccess: (state, action) => initialState,
    logoutFailure: (state, action) => state
      .set('logoutLoading', false),
    // SIGNUP
    signupLaunched: (state, action) => state
      .set('signupLoading', true)
      .set('signupErrorMessage', null),
    signupSuccess: (state, action) => state
      .set('signupLoading', false)
      .set('signupErrorMessage', null),
    signupFailure: (state, action) => state
      .set('signupLoading', false)
      .set('signupErrorMessage', action.payload),
    // CHANGE PASSWORD 
    changePasswordLaunched: (state, action) => state
      .set('changePasswordLoading', true)
      .set('changePasswordSuccessMessage', null)
      .set('changePasswordErrorMessage', null),
    changePasswordSuccess: (state, action) => state
      .set('changePasswordLoading', false)
      .set('changePasswordSuccessMessage', action.payload)
      .set('changePasswordErrorMessage', null),
    changePasswordFailure: (state, action) => state
      .set('changePasswordLoading', false)
      .set('changePasswordSuccessMessage', null)
      .set('changePasswordErrorMessage', action.payload),
    // GetUsersEmail
    getUsersEmailLaunched: (state, action) => state
      .set('getUsersEmailLoading', true)
      .set('usersEmail', null)
      .set('getUsersEmailError', null),
    // .set('signupEmail', action.payload),
    getUsersEmailSuccess: (state, action) => state
      .set('getUsersEmailLoading', false)
      .set('usersEmail', action.payload)
      .set('getUsersEmailError', null),
    // .set('signupEmail', action.payload),
    getUsersEmailFailure: (state, action) => state
      .set('getUsersEmailLoading', false)
      .set('usersEmail', null)
      .set('getUsersEmailError', action.payload),

    // REQUEST PASSWORD CODE (if forgotten by user)
    requestPasswordCodeLaunched: (state, action) => state
      .set('requestCodeLoading', true)
      .set('requestCodeErrorMessage', null)
      .set('forgotPasswordStep', 1),
    requestPasswordCodeSuccess: (state, action) => state
      .set('requestCodeLoading', false)
      .set('requestCodeErrorMessage', null)
      .set('forgotPasswordStep', 2),
    requestPasswordCodeFailure: (state, action) => state
      .set('requestCodeLoading', false)
      .set('requestCodeErrorMessage', action.payload)
      .set('forgotPasswordStep', 1),
    // SUBMIT NEW PASSWORD (after request passwordCode)
    submitNewPasswordLaunched: (state, action) => state
      .set('submitPasswordLoading', true)
      .set('submitPasswordErrorMessage', null),
    submitNewPasswordSuccess: (state, action) => state
      .set('submitPasswordLoading', false)
      .set('submitPasswordErrorMessage', null)
      .set('submitPasswordSuccessMessage', 'Votre mot de passe a bien été réinitialisé'),
    submitNewPasswordFailure: (state, action) => state
      .set('submitPasswordLoading', false)
      .set('submitPasswordErrorMessage', action.payload),
    // UPDATE USER
    updateUserLaunched: (state, action) => state
      .set('updateUserLoading', true)
      .set('updateUserSuccess', null)
      .set('updateUserErrorMessage', null),
    updateUserSuccess: (state, action) => state
      .set('updateUserLoading', false)
      .set('updateUserSuccess', true)
      .set('updateUserErrorMessage', null),
    updateUserFailure: (state, action) => state
      .set('updateUserLoading', false)
      .set('updateUserSuccess', false)
      .set('updateUserErrorMessage', action.payload),

    // create and get mangopayId at first shipping order
    firstShippingDeliveryLaunched: (state, action) => state
      .set('naturalUserMangopayLoading', true)
      .set('naturalUserMangopayContent', null)
      .set('naturalUserMangopayError', null),
    firstShippingDeliverySuccess: (state, action) => state
      .set('naturalUserMangopayLoading', false)
      .set('naturalUserMangopayContent', action.payload)
      .set('naturalUserMangopayError', null),
    firstShippingDeliveryFailure: (state, action) => state
      .set('naturalUserMangopayLoading', false)
      .set('naturalUserMangopayContent', null)
      .set('naturalUserMangopayError', action.payload),


    getPreAuthorizationLaunched: (state, action) => state
      .set('getPreAuthorizationLoading', true)
      .set('getPreAuthorizationId', action.payload)
      .set('getPreAuthorizationContent', null)
      .set('getPreAuthorizationErrorMessage', null),
    getPreAuthorizationSuccess: (state, action) => state
      .set('getPreAuthorizationLoading', false)
      .set('getPreAuthorizationId', null)
      .set('getPreAuthorizationContent', action.payload.preAuthorization)
      .set('getPreAuthorizationErrorMessage', null),
    getPreAuthorizationFailure: (state, action) => state
      .set('getPreAuthorizationLoading', false)
      .set('getPreAuthorizationId', null)
      .set('getPreAuthorizationContent', null)
      .set('getPreAuthorizationErrorMessage', null),

    // Create and get Delivery Simulation
    getDeliverySimulationLaunched: (state, action) => state
      .set('getDeliverySimulationLoading', true)
      .set('getDeliverySimulationContent', null)
      .set('getDeliverySimulationErrorMessage', null),
    getDeliverySimulationSuccess: (state, action) => state
      .set('getDeliverySimulationLoading', false)
      .set('getDeliverySimulationContent', action.payload)
      .set('getDeliverySimulationErrorMessage', null),
    getDeliverySimulationFailure: (state, action) => state
      .set('getDeliverySimulationLoading', false)
      .set('getDeliverySimulationContent', null)
      .set('getDeliverySimulationErrorMessage', null),

    // Create card Registration
    createCardRegistrationLaunched: (state, action) => state
      .set('createCardRegistrationLoading', true)
      .set('createCardRegistrationErrorMessage', null),
    createCardRegistrationSuccess: (state, action) => state
      .set('createCardRegistrationLoading', false)
      .set('createCardRegistrationErrorMessage', null),
    createCardRegistrationFailure: (state, action) => state
      .set('createCardRegistrationLoading', false)
      .set('createCardRegistrationErrorMessage', action.payload),
    createCardRegistrationReset: (state, action) => state
      .set('createCardRegistrationLoading', false)
      .set('createCardRegistrationErrorMessage', null),

    validateAndPostCustomerOrderLaunched: (state, action) => state
      .set('postCustomerOrderLoading', true)
      .set('postCustomerOrderError', null)
      .set('postCustomerOrdeSuccess', null),
    validateAndPostCustomerOrderSuccess: (state, action) => state
      .set('postCustomerOrderLoading', false)
      .set('postCustomerOrderError', null)
      .set('postCustomerOrdeSuccess', true),
    validateAndPostCustomerOrderError: (state, action) => state
      .set('postCustomerOrderLoading', false)
      .set('postCustomerOrderError', true)
      .set('postCustomerOrdeSuccess', null),

    searchCustomerOrdersLaunched: (state, action) => state
      .set('searchCustomerOrdersLoading', true)
      .set('searchCustomerOrdersSuccess', null)
      .set('searchCustomerOrdersFailure', null)
      .set('allCustomerOrders', null),
    searchCustomerOrdersSuccess: (state, action) => state
      .set('searchCustomerOrdersLoading', false)
      .set('searchCustomerOrdersSuccess', true)
      .set('searchCustomerOrdersFailure', null)
      .set('allCustomerOrders', action.payload),
    searchCustomerOrdersFailure: (state, action) => state
      .set('searchCustomerOrdersLoading', false)
      .set('searchCustomerOrdersSuccess', null)
      .set('searchCustomerOrdersFailure', true)
      .set('allCustomerOrders', null),

    searchSellerOrdersLaunched: (state, action) => state
      .set('searchSellerOrdersLoading', true)
      .set('searchSellerOrdersSuccess', null)
      .set('searchSellerOrdersFailure', null)
      .set('sellerOrders', null),
    searchSellerOrdersSuccess: (state, action) => state
      .set('searchSellerOrdersLoading', false)
      .set('searchSellerOrdersSuccess', true)
      .set('searchSellerOrdersFailure', null)
      .set('sellerOrders', action.payload),
    searchSellerOrdersFailure: (state, action) => state
      .set('searchSellerOrdersLoading', false)
      .set('searchSellerOrdersSuccess', null)
      .set('searchSellerOrdersFailure', true)
      .set('sellerOrders', null),

    getCustomerOrderLaunched: (state, action) => state
      .set('getCustomerOrderLoading', true)
      .set('getCustomerOrderSuccess', null)
      .set('getCustomerOrderFailure', null)
      .set('customerOrder', null),
    getCustomerOrderSuccess: (state, action) => state
      .set('getCustomerOrderLoading', false)
      .set('getCustomerOrderSuccess', true)
      .set('getCustomerOrderFailure', null)
      .set('customerOrder', action.payload),
    getCustomerOrderFailure: (state, action) => state
      .set('getCustomerOrderLoading', false)
      .set('getCustomerOrderSuccess', null)
      .set('getCustomerOrderFailure', true)
      .set('customerOrder', null),

    // Newsletter subscription
    newsletterSubscriptionLaunched: (state, action) => state
      .set('newsletterSubscriptionLoading', true)
      .set('newsletterSubscriptionErrorMessage', null),
    newsletterSubscriptionSuccess: (state, action) => state
      .set('newsletterSubscriptionLoading', false)
      .set('newsletterSubscriptionErrorMessage', null),
    newsletterSubscriptionFailure: (state, action) => state
      .set('newsletterSubscriptionLoading', false)
      .set('newsletterSubscriptionErrorMessage', action.payload),

    // Search results
    getSearchLaunched: (state, action) => state
      .set('searchValue', action.payload.value)
      // .set('query', null) // We need to save value on searchSuccess
      // .set('sellers', null) // We need to save value on searchSuccess
      // .set('category', null) // We need to save value on searchSuccess
      .set('isLoading', true)
      .set('searchResults', null),
    // .set('page', null)
    // .set('lastPage', null),
    getSearchSuccess: (state, action) => state
      .set('searchValue', null)
      .set('query', action.payload.query)
      .set('sellers', action.payload.sellers)
      .set('category', action.payload.category)
      .set('isLoading', false)
      .set('searchResults', action.payload.productContents)
      .set('page', action.payload.page)
      .set('lastPage', action.payload.lastPage),
    getSearchFailure: (state, action) => state
      .set('searchValue', null)
      .set('query', null)
      .set('sellers', [])
      .set('category', [])
      .set('isLoading', false)
      .set('searchErrorMessage', action.payload) // TODO : check if ok 
      .set('page', null)
      .set('lastPage', null),

    // Search results pagination : TODO CHECK SI BESOIN
    setSearchSkip: (state, action) => state
      .set('searchSkip', action.payload),

    // Contact
    contactLaunched: (state, action) => state
      .set('contactLoading', true)
      .set('contactSuccess', false)
      .set('contactError', null),
    contactSuccess: (state, action) => state
      .set('contactLoading', false)
      .set('contactSuccess', action.payload)
      .set('contactError', null),
    contactFailure: (state, action) => state
      .set('contactLoading', false)
      .set('contactSuccess', false)
      .set('contactError', action.payload),

    // clientContact
    clientContactLaunched: (state, action) => state
      .set('clientContactLoading', true)
      .set('clientContactSuccessState', null)
      .set('clientContactError', null),
    clientContactSuccess: (state, action) => state
      .set('clientContactLoading', false)
      .set('clientContactSuccessState', true)
      .set('clientContactError', null),
    clientContactFailure: (state, action) => state
      .set('clientContactLoading', false)
      .set('clientContactSuccessState', false)
      .set('clientContactError', true),

    // incident
    incidentLaunched: (state, action) => state
      .set('incidentLoading', true)
      .set('incidentSuccess', null)
      .set('incidentError', null),
    incidentSuccess: (state, action) => state
      .set('incidentLoading', false)
      .set('incidentSuccess', true)
      .set('incidentError', false),
    incidentFailure: (state, action) => state
      .set('incidentLoading', false)
      .set('incidentSuccess', false)
      .set('incidentError', true),

    // Popup
    openPopup: (state, action) => state
      .set('popupOpen', action.payload.open)
      .set('popupMessage', action.payload.message)
      .set('popupError', action.payload.error)
      .set('popupId', action.payload.popupId),

    // ResetPopup
    resetPopupStore: (state, action) => state
      .set('popupOpen', false)
      .set('popupMessage', null)
      .set('popupError', false)
      .set('popupId', null)
      .set('shouldResetForm', null),
    // Get A ContentType's entries
    getContentTypeEntriesLaunched: (state, action) => state
      .set('isLoading', true),
    getContentTypeEntriesSuccess: (state, action) => state
      .set('isLoading', false)
      .set('contentTypeEntries', action.payload),
    getContentTypeEntriesFailure: (state, action) => state
      .set('isLoading', false)
      .set('contentTypeEntries', []),
  }
});

export const {
  getCurrentSessionLaunched,
  getCurrentSessionSuccess,
  getCurrentSessionFailure,
  loginLaunched,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  logoutLaunched,
  signupLaunched,
  signupSuccess,
  signupFailure,
  changePasswordLaunched,
  changePasswordSuccess,
  changePasswordFailure,
  getUsersEmailLaunched,
  getUsersEmailSuccess,
  getUsersEmailFailure,
  requestPasswordCodeLaunched,
  requestPasswordCodeSuccess,
  requestPasswordCodeFailure,
  submitNewPasswordLaunched,
  submitNewPasswordSuccess,
  submitNewPasswordFailure,
  updateUserLaunched,
  updateUserSuccess,
  updateUserFailure,
  firstShippingDeliveryLaunched,
  firstShippingDeliverySuccess,
  firstShippingDeliveryFailure,
  getPreAuthorizationLaunched,
  getPreAuthorizationSuccess,
  getPreAuthorizationFailure,
  getDeliverySimulationLaunched,
  getDeliverySimulationSuccess,
  getDeliverySimulationFailure,
  createCardRegistrationLaunched,
  createCardRegistrationSuccess,
  createCardRegistrationFailure,
  createCardRegistrationReset,
  validateAndPostCustomerOrderLaunched,
  validateAndPostCustomerOrderSuccess,
  validateAndPostCustomerOrderError,
  searchCustomerOrdersLaunched,
  searchCustomerOrdersSuccess,
  searchCustomerOrdersFailure,
  searchSellerOrdersLaunched,
  searchSellerOrdersSuccess,
  searchSellerOrdersFailure,
  getCustomerOrderLaunched,
  getCustomerOrderSuccess,
  getCustomerOrderFailure,
  newsletterSubscriptionLaunched,
  newsletterSubscriptionSuccess,
  newsletterSubscriptionFailure,
  getSearchLaunched,
  getSearchSuccess,
  getSearchFailure,
  setSearchSkip,
  openPopup,
  resetPopupStore,
  getContentTypeEntriesLaunched,
  getContentTypeEntriesSuccess,
  getContentTypeEntriesFailure,
  contactLaunched,
  contactSuccess,
  contactFailure,
  clientContactLaunched,
  clientContactSuccess,
  clientContactFailure,
  incidentLaunched,
  incidentSuccess,
  incidentFailure,
} = actions;

export default reducer;
