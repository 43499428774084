import { all, put, takeLatest, delay } from 'redux-saga/effects';
import algoliasearch from 'algoliasearch';
import { API } from 'aws-amplify';
import { config } from '../../utils/amplify';
import configApp from '../../utils/libs/config';
import {
  searchSuccess,
  searchFailure,
  openSnackBar,
  closeSnackBar,
  clearSnackBar,
  getDeliveryZonesSuccess,
  getDeliveryZonesFailure,
  getSellerDeliveryModesSuccess,
  getSellerDeliveryModesFailure,
} from './reducer';
const client = algoliasearch(configApp.getAlgoliaAppId(), configApp.getAlgoliaApiKey());
const productIndexes = {
  default: client.initIndex(configApp.getAlgoliaProductIndexName()),
  ascPrice: client.initIndex(configApp.getAlgoliaProductIndexName() + "-AscPrice"),
  descPrice: client.initIndex(configApp.getAlgoliaProductIndexName() + "-DescPrice"),
  descWeight: client.initIndex(configApp.getAlgoliaProductIndexName() + "-DescWeight")
};

function* doSellerPageSearch(action) {
  try {
    const {
      searchPage,
      searchSellerName,
      searchLocale,
      searchPriceMin,
      searchPriceMax,
      orderType,
      searchByCategories
    } = action.payload || {};

    let filterIndexName = "default";
    let sellerNameTrimmed = searchSellerName.replace(/'/g, "\\'"); // escape quote with antislash
    let filtersParam = `sellerName:'${sellerNameTrimmed}' AND locale:'${searchLocale}'`;
    if (searchPriceMin && searchPriceMax) {
      filtersParam += ` AND price:${searchPriceMin} TO ${searchPriceMax}`
    } else if (searchPriceMax && !searchPriceMin) {
      filtersParam += ` AND price:0 TO ${searchPriceMax}`
    }
    if (searchByCategories) {
      if (searchByCategories.length > 0) {
        filtersParam += ` AND (`
        searchByCategories.forEach((element, index) => {
          let categorieName = element.replace(/'/g, "\\'"); // espace quote with antislash
          if (index === 0) {
            filtersParam += `categoryPath:'${categorieName}'`
          } else {
            filtersParam += ` OR categoryPath:'${categorieName}'`
          }
        });
        filtersParam += `)`
      }
    }
    if (orderType && orderType === "asc-price") {
      filterIndexName = "ascPrice";
    }
    if (orderType && orderType === "desc-price") {
      filterIndexName = "descPrice";
    }
    if (orderType && orderType === "desc-weight") {
      filterIndexName = "descWeight";
    }
    const results = yield productIndexes[filterIndexName].search("", {
      page: searchPage,
      hitsPerPage: configApp.getProductsPerPageInPageLists(),
      filters: filtersParam,
    });
    yield put(searchSuccess({
      productContents: results.hits,
      page: results.page,
      lastPage: results.nbPages,
    }));
  } catch (e) {
    yield put(searchFailure(e));
  }
}

function* setSellerSnackBar() {
  try {
    yield put(openSnackBar({ message: "Produit(s) ajouté(s) avec succès !", error: false }));
    yield delay(4000);
    yield put(closeSnackBar());
    yield delay(200);
    yield put(clearSnackBar());
  } catch (error) {
    console.log(error);
    yield put(openSnackBar({ message: "Une erreur est survenue !", error: true }));
    yield delay(5000);
    yield put(closeSnackBar());
    yield delay(200);
    yield put(clearSnackBar());
  }
}

function* getDeliveryZones() {
  try {
    const deliveryZones = yield API.get(config.apiGateway.NAME, `/deliveryZones`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    yield put(getDeliveryZonesSuccess(deliveryZones));
  } catch (error) {
    yield put(getDeliveryZonesFailure(error));
  }
}

function* getSellerDeliveryModes(action) {
  const sellerId = action.payload;
  try {
    const sellerDeliveryModes = yield API.get(config.apiGateway.NAME, `/visitorDeliveryModeConfigurations?sellerId=${sellerId}`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    yield put(getSellerDeliveryModesSuccess(sellerDeliveryModes));
  } catch (error) {
    yield put(getSellerDeliveryModesFailure(error));
  }
}

export default function* leadCreationSaga() {
  yield all([
    takeLatest('sellerPage/searchLaunched', doSellerPageSearch),
    takeLatest('sellerPage/sellerLaunchedSnackBar', setSellerSnackBar),
    takeLatest('sellerPage/getDeliveryZonesLaunched', getDeliveryZones),
    takeLatest('sellerPage/getSellerDeliveryModesLaunched', getSellerDeliveryModes),
  ]);
}