import config from '../../utils/libs/mangopayConfig.js';
const Buffer = require("buffer").Buffer;
var axios = require('axios');
var qs = require('qs');
const mangopayURL = config.getMangopayURL();
const clientId = config.getClientID();
const apiKey = config.getApiKey();
const authBearer = Buffer.from(clientId + ':' + apiKey).toString('base64');

export const CreateToken = async (mangopayId) => {
  // Stage 1 : createToken
  try {
    var data = JSON.stringify({
      "UserId": mangopayId,
      "Currency": "EUR",
      "CardType": "CB_VISA_MASTERCARD"
    });

    var config = {
      method: 'post',
      url: mangopayURL + clientId + '/CardRegistrations',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + authBearer
      },
      data: data
    };
    const axiosData = await axios(config)
    const response = (axiosData.data)
    return response
  }
  catch (e) {
    console.log('Error in mangopay-CreateToken :', e);
    throw e;
  }
}

export const PostCardInfo = async (tokenResult, cardNumber, cardExpirationDate, cardCvx) => {
  // Stage 2 : PostCardInfo

  try {
    const preRegData = tokenResult.PreregistrationData;
    const accessKeyRef = tokenResult.AccessKey;
    const registrationURL = tokenResult.CardRegistrationURL;

    var data = qs.stringify({
      'data': preRegData,
      'accessKeyRef': accessKeyRef,
      'cardNumber': cardNumber,
      'cardExpirationDate': cardExpirationDate,
      'cardCvx': cardCvx
    });
    var config = {
      method: 'post',
      url: registrationURL,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    };

    const axiosData = await axios(config)
    const response = axiosData.data
    return response
  }
  catch (e) {
    console.log('Error in mangopay-PostCardInfo :', e);
  }
}

export const PutTokenData = async (postCardResult, tokenResultId) => {
  // Stage 3 : PutTokenData

  try {
    var data = JSON.stringify({
      "RegistrationData": postCardResult
    });

    var config = {
      method: 'put',
      url: mangopayURL + clientId + '/CardRegistrations/' + tokenResultId,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + authBearer
      },
      data: data
    };

    const axiosData = await axios(config)
    const response = axiosData.data
    return response
  }
  catch (e) {
    console.log('Error in mangopay-PutTokenData :', e);
  }
}

export const PostPreAuthorization = async (mangopayId, CardId, Amount, IpAddress) => {
  // Stage 4 : PostPreAuthorization

  let currentURL = typeof window !== 'undefined' ? window.location.href : '';
  if (currentURL.endsWith('/')) {
    currentURL = currentURL.slice(0, -1);
  }
  try {
    var data = JSON.stringify({
      "AuthorId": mangopayId,
      "DebitedFunds": {
        "Currency": "EUR",
        "Amount": Amount
      },
      "Culture": "FR",
      "CardID": CardId,
      "ResultMessage": "The transaction was successful",
      "ExecutionType": "DIRECT", //  only DIRECT available here, for other mode we could have => WEB / EXTERNAL_INSTRUCTION
      "SecureMode": "FORCE", // Select a 3DS1 and 3DS2 protocol. "DEFAULT" = Frictionless / "NO_CHOICE" = eligible for Frictionless / "FORCE" = will force customer authentification.
      "SecureModeReturnURL": currentURL + "/paymentStatus",
      /**
       * @IpsAddress
       * IpAddress is received from http request on th API : "https://ipapi.co/ip/"
       * for other API, please see "https://stackoverflow.com/questions/391979/how-to-get-clients-ip-address-using-javascript"
       * > 1,000 requests per day
       * > Requires SSL (https)
       * > Requires non-null Origin request header
       * > Returns only IPv6 address if you have that
       */
      "IpAddress": IpAddress, // TODO : change it for a call via our API using congito datas
      "BrowserInfo": {
        "AcceptHeader": "text/html, application/xhtml+xml, application/xml;q=0.9, /;q=0.8",
        "JavaEnabled": navigator.javaEnabled(), // * Deprecated but no other solution
        "Language": navigator.language || navigator.userLanguage,
        "ColorDepth": window.screen.colorDepth,
        "ScreenHeight": window.screen.height,
        "ScreenWidth": window.screen.width,
        "TimeZoneOffset": new Date().getTimezoneOffset().toString(),
        "UserAgent": navigator.userAgent,
        "JavascriptEnabled": true // It is obligated that the client use Javascript at this moment of the payin (in theory)
      }
    });

    var config = {
      method: 'post',
      url: mangopayURL + clientId + '/Preauthorizations/Card/Direct/',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + authBearer
      },
      data: data
    };

    const axiosData = await axios(config)
    const response = axiosData.data
    return response
  }
  catch (e) {
    console.log('Error in mangopay-PostPreAuthorization :', e);
  }
}

// Get Preauthorization after paiement
export const GetPreAuthorization = async (preAuthId) => {

  try {
    var config = {
      method: 'get',
      url: mangopayURL + clientId + '/Preauthorizations/' + preAuthId,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + authBearer
      },
    };

    const axiosData = await axios(config)
    const response = axiosData.data
    return response

  }
  catch (e) {
    console.log("Error in mangopay-GetPreAuthorization ", e);
  }
}

/**
  * @see https://docs.mangopay.com/guide/errors
*/
export const translatePaymentError = (errorMessage) => {
  switch (errorMessage) {
    // Tokenization / Card registration errors( => type of mangopay Error)
    case 'Token processing error':
      return 'Erreur : Problème de génération de jeton, vérifiez bien que vous avez envoyé les bons paramètres';
    case 'Token input Error':
      return 'Erreur : Problème lors de l\'envoi du jeton. Le délai de 20min a dû être dépasse pour créer l\'autorisation ou la payin';
    case 'Card number: invalid format':
      return 'Erreur : Format invalide des numéros de carte, veuillez vérifier votre saisie';
    case 'Expiry date: missing or invalid format':
      return 'Erreur : Format invalide de la date d\'expiration, veuillez vérifier votre saisie';
    case 'CVV: missing or invalid format':
      return 'Erreur : Format invalide des CVV, veuillez vérifier votre saisie';
    case 'Callback URL: Invalid format':
      return 'Erreur : Problème lors de la redirection';
    case 'Registration data : Invalid format':
      return 'Erreur : Les données de réservation sont invalides, veuillez contacter le support de cette marketplace';
    // Card input errors
    case 'Invalid card number':
      return 'Erreur : Numéro de carte invalide';
    case 'Invalid cardholder name':
      return 'Erreur : Le nom du détenteur de la carte ne correspond pas à celui du véritable propriétaire';
    case 'Invalid PIN code':
      return 'Erreur : Code PIN invalide';
    case 'Invalid PIN format':
      return 'Erreur : Le format du code PIN est invalide';
    // Transaction refused
    case 'Transaction refused by the bank (Do not honor)':
      return 'Erreur : Transaction refusée par la banque (Impossible d\'honorer)';
    case 'Transaction refused by the bank (Amount limit)':
      return 'Erreur : Transaction refusée par la banque (Limite de montant)';
    case 'Transaction refused by the terminal':
      return 'Erreur : Transaction refusé par le terminal de paiement';
    case 'Transaction refused by the bank (card limit reached)':
      return 'Erreur : Transaction refusé par la banque (limite de carte atteinte)';
    case 'The card has expired':
      return 'Erreur : La carte a expiré et ne peut pas être utilisée';
    case 'The card is inactive':
      return 'Erreur : La carte est signalée comme inactive par la banque et ne peut pas être utilisée';
    case 'Transaction refused: the Debited Wallet and the Credited Wallet must be different':
      return 'Erreur : Transaction refusé : le wallet de débit et le wallet crédité doivent être différents';
    case 'The payment period has expired':
      return 'Erreur : La période de paiement a expiré(le payin expire après un mois si le fond n\'a pas été reçu par Mangopay durant cette période)';
    case 'The payment has been refused':
      return 'Erreur : Paiement refusé';
    case 'The card is not active':
      return 'Erreur : La carte a été désactivée par le service de paiement et n\'est plus utilisable';
    case 'Maximum number of attempts reached':
      return 'Erreur : Nombre maximum d\'essai atteint';
    case 'Maximum amount exceeded':
      return 'Erreur : Le montant limite de la carte a été atteint';
    case 'Maximum Uses Exceeded':
      return 'Erreur : Le montant maximal d\'utilisation de la carte a été atteint. Veuillez réessayer dans 24h';
    case 'Debit limit exceeded':
      return 'Erreur : La limite de débit a été atteinte';
    case 'Amount limit':
      return 'Erreur : Le nombre journalier d\'autorisation a été dépassé. Veuillez contacter le support pour ajuster le nombre de transactions journalières';
    case 'An initial transaction with the same card is still pending':
      return 'Erreur : Une transaction similaire avec la même carte est déjà en cours de traitement';
    case 'Invalid or missing bank details':
      return 'Erreur : Les détails du compte bancaire sont invalides';
    case 'Blocked due to User Balance limitations (maximum owned amount reached)':
      return 'Erreur : Transaction bloquée à cause de la limite de la balance de l\'utilisateur (Montant possédé maximum atteint)';
    case 'Transaction refused':
      return 'Erreur : La transaction a été refusée par la banque. Contactez votre banque pour plus d\'informations';
    case 'Paypal Data validation error':
      return 'Erreur : Problème de validation Paypal, assurez-vous d\'envoyer les bonnes données. L\'un des champs requis est soit manquant soit invalide';
    // SecureMode / 3DSecure errors
    case 'Secure mode: 3DSecure authentication is not available':
      return 'Erreur : SecureMode : L\'authentification 3DSecure n\'est pas disponible, veuillez contacter notre support pour débloquer la situation';
    case 'Secure mode: The 3DSecure authentication session has expired	':
      return 'Erreur : SecureMode: l\'authentification 3D Secure a expiré';
    case 'Secure mode: The card is not compatible with 3DSecure':
      return 'Erreur : SecureMode: La carte bancaire n\'est pas compatible avec 3DSecure';
    case 'Secure mode: The card is not enrolled with 3DSecure':
      return 'Erreur : SecureMode: La carte bancaire n\'est pas inscrite 3DSecure';
    case 'Secure mode: 3DSecure authentication has failed':
      return 'Erreur : SecureMode: L\'authentification 3DSecure a échoué';
    case 'Secure mode: The 3DSecure authentication has failed':
      return 'Erreur : SecureMode: L\'authentification 3DSecure a échoué';
    case 'Secure mode: Soft decline':
      return 'Erreur : SecureMode: Echec 3DSecure, une sécurité forte est requise afin de procéder au paiement';
    case 'Secure mode: 3DSecure authentication requested':
      return 'Erreur : SecureMode: l\'authentification 3DSecure a été demandée, mais n\'a pas été reçue';
    // Technical errors
    case 'PSP configuration error':
      return 'Erreur : Problème PSP de configuration';
    case 'PSP technical error':
      return 'Erreur : Plusieurs causes possibles : \n Utilisation d\'une carte ne faisant pas de 3DSecure \n Le montant est supérieur au montant maximum par transaction \n La carte n\'est pas supporté par Mangopay \n';
    case 'Bank technical error':
      return 'Erreur : La banque a refusé le paiement pour une raison inconnue. Veuillez les contacter pour plus d\'informations';
    case 'Technical error':
      return 'Erreur : Problème technique';
    case 'PSP timeout please try later':
      return 'Erreur : La requête PSP a expiré, veuillez réessayer plus tard';
    case 'Bank not supported for Giropay':
      return 'Erreur : La banque ne supporte pas les paiements Giropay';


    // Operation failed
    case 'Generic Operation error':
      return 'Erreur : Problème Générique, un incident ou un problème de connexion a annulé la transaction';
    case 'Unsufficient wallet balance':
      return 'Erreur : Pas assez d\'argent sur le wallet pour assurer la transaction';
    case 'Author is not the wallet owner':
      return 'Erreur : L\'auteur n\'est pas le propriétaire du wallet';
    case 'Transaction amount is higher than maximum permitted amount':
      return 'Erreur : Le montant de la transaction est supérieur au montant maximum autorisé';
    case 'Transaction amount is lower than minimum permitted amount':
      return 'Erreur : Le montant de la transaction est inférieur au montant minimum autorisé';
    case 'Invalid transaction amount':
      return 'Erreur : Montant de la transaction invalide, votre banque l\'a refusé. Veuillez contater votre banque pour plus d\'informations';
    case 'CreditedFunds must be more than 0 (DebitedFunds can not equal Fees)':
      return 'Erreur : Les fonds crédités doivent être supérieur à 0. (Les fonds débités ne peuvent pas être égal aux taxes)';

    // PayIn Web errors
    case 'User has not been redirected':
      return 'Erreur : Problème de redirection';
    case 'User canceled the payment':
      return 'Erreur : Paiement annulé';
    case 'The transaction has been cancelled by the user':
      return 'Erreur : Transaction annulée par l\'utilisateur';
    case 'User is filling in the payment card details':
      return 'Erreur : L\'utilisateur est en train de remplir ses informations bancaires';
    case 'User has not been redirected then the payment session has expired':
      return 'Erreur : L\'utilisateur n\'a pas été redirigé et la session a expiré';
    case 'User has let the payment session expire without paying':
      return 'Erreur : L\'utilisateur a laissé la session expiré sans payer';
    case 'The user does not complete transaction':
      return 'Erreur : L\'utilisateur n\'a pas complété la transaction';

    //  Refund transaction errors
    case 'Transaction has already been successfully refunded':
      return 'Erreur : La transaction a déjà été remboursée';
    case 'The refund cannot exceed initial transaction amount':
      return 'Erreur : Il est impossible de rembourser plus que le montant initial de la transaction';
    case 'The refunded fees cannot exceed initial fee amount':
      return 'Erreur : Il est impossible de rembouser des taxes qui dépassent les taxes initiales';
    case 'Balance of client fee e-wallet insufficient':
      return 'Erreur : Les taxes ne peuvent pas être incluses dans un remboursement si le wallet des frais a déjà été débité et que le montant restant a été payé';
    case 'Duplicated operation: you cannot refund the same amount more than once for a transaction during the same day':
      return 'Erreur : Opération dupliquée : vous ne pouvez pas rembourser plus d\'une fois le même montant pour une transaction au cours de la même journée';
    case 'The transaction cannot be refunded (max 11 months)':
      return 'Erreur : La transaction ne peut pas être remboursée (max 11 mois)';

    // Error caught before paiement occurred
    case "TypeError: Cannot read properties of undefined (reading 'PreregistrationData')":
      return 'Erreur : Problème durant la génération du jeton de connexion Mangopay, vérifiez les clefs API';
    case "TypeError: Cannot read properties of undefined (reading 'Status')":
      return 'Erreur : Problème sur le champ "Status", le montant à payer est-il déclaré?';
    case 'Error: Request failed with status code 401':
      return 'Erreur : La requête a échoué avec un status d\'erreur 401. (impossible de se connecter à Mangopay, il manque des informations d\'authentification)'

    // TODO add other from https://docs.mangopay.com/guide/errors

    default:
      return 'Erreur lors du paiement : [' + errorMessage + ']';
  }
};