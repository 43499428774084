import { all, put, takeLatest, delay } from 'redux-saga/effects';
import algoliasearch from 'algoliasearch';
import config from '../../utils/libs/config';
import {
  searchSuccess,
  searchFailure,
  openSnackBar,
  closeSnackBar,
  clearSnackBar
} from './reducer';
const client = algoliasearch(config.getAlgoliaAppId(), config.getAlgoliaApiKey());
const productIndexes = {
  default: client.initIndex(config.getAlgoliaProductIndexName()),
  ascPrice: client.initIndex(config.getAlgoliaProductIndexName() + "-AscPrice"),
  descPrice: client.initIndex(config.getAlgoliaProductIndexName() + "-DescPrice"),
  descWeight: client.initIndex(config.getAlgoliaProductIndexName() + "-DescWeight")
};

function* doPageListSearch(action) {
  try {
    const {
      orderType,
      searchPage,
      searchCategoryPath,
      searchLocale,
      searchPriceMin,
      searchPriceMax,
      searchBySeller,
      searchFacets,
    } = action.payload || {};

    let filterIndexName = "default";
    let filtersParam = `locale:${searchLocale} AND (categoryPath:${searchCategoryPath} OR secondarySvePath:${searchCategoryPath})`;
    if (searchPriceMin && searchPriceMax) {
      filtersParam += ` AND price:${searchPriceMin} TO ${searchPriceMax}`
    } else if (searchPriceMax && !searchPriceMin) {
      filtersParam += ` AND price:0 TO ${searchPriceMax}`
    }
    if (searchBySeller) {
      if (searchBySeller.length > 0) {
        filtersParam += ` AND (`
        searchBySeller.forEach((element, index) => {
          let sellerName = element.replace(/'/g, "\\'"); // espace quote with antislash
          if (index === 0) {
            filtersParam += `sellerName:'${sellerName}'`
          } else {
            filtersParam += ` OR sellerName:'${sellerName}'`
          }
        });
        filtersParam += `)`
      }
    }
    if (searchFacets) {
      searchFacets.forEach((facet) => {
        if (facet.value.length > 0) {
          filtersParam += ` AND (`
          facet.value.forEach((element, index) => {
            if (index === 0) {
              filtersParam += `${facet.code}:'${element}'`
            } else {
              filtersParam += ` OR ${facet.code}:'${element}'`
            }
          });
          filtersParam += `)`
        }
      })
    }
    if (orderType && orderType === "asc-price") {
      filterIndexName = "ascPrice";
    }
    if (orderType && orderType === "desc-price") {
      filterIndexName = "descPrice";
    }
    if (orderType && orderType === "desc-weight") {
      filterIndexName = "descWeight";
    }
    const results = yield productIndexes[filterIndexName].search("", {
      page: searchPage,
      hitsPerPage: config.getProductsPerPageInPageLists(),
      filters: filtersParam,
    });
    yield put(searchSuccess({
      productContents: results.hits,
      page: results.page,
      lastPage: results.nbPages,
    }));
  } catch (e) {
    yield put(searchFailure(e));
  }
}

function* setSnackBar() {
  try {
    yield put(openSnackBar({ message: "Produit(s) ajouté(s) avec succès !", error: false }));
    yield delay(4000);
    yield put(closeSnackBar());
    yield delay(200);
    yield put(clearSnackBar());
  } catch (error) {
    console.log(error);
    yield put(openSnackBar({ message: "Une erreur est survenue !", error: true }));
    yield delay(5000);
    yield put(closeSnackBar());
    yield delay(200);
    yield put(clearSnackBar());
  }
}

export default function* leadCreationSaga() {
  yield all([
    takeLatest('pageList/searchLaunched', doPageListSearch),
    takeLatest('pageList/launchedSnackBar', setSnackBar),
  ]);
}
