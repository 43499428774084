module.exports.getAuthBearer = () => {
  return process.env.GATSBY_MANGOPAY_AUTH_BEARER;
}

module.exports.getMangopayURL = () => {
  return process.env.GATSBY_MANGOPAY_URL;
}

module.exports.getClientID = () => {
  return process.env.GATSBY_MANGOPAY_CLIENT_ID;
}

module.exports.getApiKey = () => {
  return process.env.GATSBY_MANGOPAY_API_KEY;
}