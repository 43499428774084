import "./src/styles/global.css";
import "tailwindcss/dist/base.min.css";
import store from "./src/state";
export const wrapRootElement = store;

// scrolls to top on page change
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const currentPosition = getSavedScrollPosition(location);
  if (
    location.pathname.startsWith(
      "/",
      "/fr/",
      "/fr/account",
      "/fr/advice",
      "/fr/coaching",
      "/fr/confirmation",
      "/fr/legal",
      "/fr/login",
      "/fr/cart",
      "/fr/checkout",
      "/fr/orders",
      "/fr/payment",
      "/fr/search-results",
      "/fr/selection",
      "/fr/seller",
      "/fr/shipping",
      "/fr/signup",
      "/fr/404",
      "/en/",
      "/en/account",
      "/en/advice",
      "/en/coaching",
      "/en/confirmation",
      "/en/legal",
      "/en/login",
      "/en/cart",
      "/en/checkout",
      "/en/orders",
      "/en/payment",
      "/en/search-results",
      "/en/selection",
      "/en/seller",
      "/en/shipping",
      "/en/signup",
      "/en/404"
    )
  ) {
    return window.scrollTo(...(currentPosition || [0, 0])), 600;
  }
};
