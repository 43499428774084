import { all, put, takeLatest, delay } from 'redux-saga/effects';
import { API } from 'aws-amplify';
import { config } from '../../utils/amplify';

import {
  getRelayPointSuccess,
  getRelayPointFailure,
  getRelayPointDetailSuccess,
  getRelayPointDetailFailure,
  getCitySuggestionSuccess,
  getCitySuggestionFailure
} from './reducer';

function* getRelayPoint(action) {
  const data = action.payload
  try {
    const result = yield API.post(config.apiGateway.NAME, `/mondialRelay/getRelayPoint`, {
      headers: {
        'x-api-key': config.apiKey
      },
      body: data
    })

    yield put(getRelayPointSuccess(result));
  } catch (e) {
    yield put(getRelayPointFailure(e));
  }
};

function* getRelayPointDetail(action) {
  const data = action.payload
  try {
    const result = yield API.post(config.apiGateway.NAME, `/mondialRelay/getRelayPoint`, {
      headers: {
        'x-api-key': config.apiKey
      },
      body: data
    })

    yield put(getRelayPointDetailSuccess(result));
  } catch (e) {
    yield put(getRelayPointDetailFailure(e));
  }
};

function* getCitySuggestion(action) {
  const data = action.payload;
  try {
    const result = yield API.post(config.apiGateway.NAME, `/mondialRelay/zipcode`, {
      headers: {
        'x-api-key': config.apiKey
      },
      body: data
    })
    yield put(getCitySuggestionSuccess(result))
  } catch (e) {
    yield put(getCitySuggestionFailure(e))
  }
};

export default function* leadCreationSaga() {
  yield all([
    takeLatest('shoppingDeliveryPage/getRelayPointLaunched', getRelayPoint),
    takeLatest('shoppingDeliveryPage/getRelayPointDetailLaunched', getRelayPointDetail),
    takeLatest('shoppingDeliveryPage/getCitySuggestionLaunched', getCitySuggestion)
  ])
}